<template>
  <div class="flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-2">
        <div class="flex">
          <button
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()"
          >
            <i class="material-icons">chevron_left</i>
          </button>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500">
            New Team
          </div>
          <div v-else class="text-2xl font-bold text-green-500">Edit team</div>
        </div>
      </div>

      <div class="">
        <div class="bg-white rounded-lg p-6 pt-2">
          <div class="grid lg:grid-cols-2 gap-6">
            <div
              class="border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="name" class="bg-white text-gray-700 px-1">
                    Name <span :class="$colors.required">*</span>
                  </label>
                </p>
              </div>
              <p>
                <input
                  id="name"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.name"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>

            <div
              class="border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="description" class="bg-white text-gray-700 px-1"
                    >Description
                  </label>
                </p>
              </div>
              <p>
                <input
                  id="description"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.description"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>
            <div
              class="border hover:border-green-500 w-full focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="customerPhone" class="bg-white text-gray-700 px-1"
                    >Country ({{ $t("warehouse") }})
                    <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <p>
                <v-select
                  @input="setSelectedContry"
                  v-model="formData.country"
                  :options="warehouses"
                  label="name"
                >
                  <template slot="option" slot-scope="option">
                    <img
                      class="flag-img"
                      :src="$f.getCountryCode(option.code)"
                    />
                    {{ option.name }}
                  </template>
                </v-select>
              </p>
            </div>
          </div>
          <div>
            <!-- ************************************************************************************ -->
            <!-- ************************************Select Sellers***********************************-->

            <div v-if="formData.country" class="w-full">
              <div class="flex w-full space-x-6 h-full overflow-hidden">
                <div
                  class="border mt-8 w-1/2 hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="name" class="bg-white text-gray-700 px-1">
                        Add Sellers to group
                        <span :class="$colors.required"> *</span>
                      </label>
                    </p>
                  </div>
                  <div class="md:w-full flex flex-col items-center p-1">
                    <div class="w-full">
                      <div class="flex flex-col items-center relative">
                        <div class="w-full">
                          <div
                            class="mt-2 p-1 bg-white flex border border-gray-200 rounded"
                          >
                            <div class="flex flex-auto flex-wrap"></div>
                            <input
                              @input="searchSellersOrtelecon($event, 'Seller')"
                              v-model="searchTextSeller"
                              placeholder="Search "
                              class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                            />
                            <div
                              class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
                            >
                              <button
                                class="cursor-pointer w-6 h-6 text-green-500 outline-none focus:outline-none"
                              >
                                <i class="material-icons">search</i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="bg-white my-4 max-h-select top-100 z-40 w-full rounded overflow-y-auto h-full"
                        >
                          <div
                            v-for="(user, index) in sellers"
                            :key="index"
                            :value="user._id"
                            class="flex flex-col w-full"
                          >
                            <div
                              class="cursor-pointer bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1 w-full rounded-t hover:bg-teal-100"
                            >
                              <div
                                class="flex w-full items-center p-2 pl-2 relative hover:border-teal-100"
                              >
                                <div class="w-6 flex flex-col items-center">
                                  <div
                                    class="flex relative w-5 h-5 justify-center items-center m-1 mr-2"
                                  >
                                    <i class="material-icons mx-2">person</i>
                                  </div>
                                </div>

                                <div class="w-full items-center flex">
                                  <div class="mx-2 mt-2">
                                    {{ user.fullName }}
                                    <div
                                      class="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-500"
                                    >
                                      {{ user.email }}
                                    </div>
                                  </div>
                                </div>

                                <div class="w-full items-center flex">
                                  <div
                                    class="text-sm text-center leading-5 text-green-500 bg-green-100 px-4 font-semibold rounded-full text-gray-900"
                                  >
                                    {{ user.type }}
                                  </div>
                                </div>

                                <div class="w-6 flex flex-col items-center">
                                  <button
                                    class="cursor-pointer w-6 h-6 text-black-500 outline-none focus:outline-none"
                                    @click="addSeller(index)"
                                  >
                                    <i class="material-icons">add</i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- ************************************Selected Sellers******************************************-->
                <div
                  class="border mt-8 w-1/2 h-200 hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="name" class="bg-white text-gray-700 px-1">
                        Selected Sellers
                        <span :class="$colors.required"></span>
                      </label>
                    </p>
                  </div>

                  <div class="md:w-full flex flex-col items-center h-64">
                    <div v-if="formData.sellers.length <= 0" class="py-2">
                      <span
                        class="w-full flex justify-center text-center py-20 border-gray-200 text-base"
                      >
                        <p
                          class="font-semibold flex text-green-500 whitespace-no-wrap"
                        >
                          <span class="">No seller selected </span>
                        </p>
                      </span>
                    </div>
                    <div class="w-full">
                      <div class="flex flex-col items-center relative">
                        <div
                          class="absolute mt-4 bg-white top-100 z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj"
                        >
                          <div
                            v-for="(user, index) in formData.sellers"
                            :key="index"
                            :value="user._id"
                            class="flex flex-col w-full"
                          >
                            <div
                              class="cursor-pointer bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1 w-full rounded-t hover:bg-teal-100"
                            >
                              <div
                                class="flex w-full items-center p-2 pl-2 relative hover:border-teal-100"
                              >
                                <div class="w-6 flex flex-col items-center">
                                  <div
                                    class="flex relative w-5 h-5 0 justify-center items-center m-1 mr-2 w-4 h-4 mt-1 rounded-full"
                                  >
                                    <div class="w-6 flex flex-col items-center">
                                      <div
                                        class="flex relative w-5 h-5 justify-center items-center m-1 mr-2"
                                      >
                                        <i class="material-icons mx-2"
                                          >person</i
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="w-full items-center flex">
                                  <div class="mx-2 -mt-1">
                                    {{ user.fullName }}
                                    <div
                                      class="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-500"
                                    >
                                      {{ user.email }}
                                    </div>
                                  </div>
                                </div>
                                <div class="w-full items-center flex">
                                  <div
                                    class="text-sm text-center leading-5 text-green-500 bg-green-100 px-4 font-semibold rounded-full text-gray-900"
                                  >
                                    {{ user.type }}
                                  </div>
                                </div>

                                <div class="w-6 flex flex-col items-center">
                                  <button
                                    class="cursor-pointer w-6 h-6 text-black-500 outline-none focus:outline-none"
                                    @click="deleteSeller(index)"
                                  >
                                    <i class="material-icons">delete</i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- *************************************************************************************************** -->
            <!-- ************************************Select Tele Consultant******************************************-->
            <div v-if="formData.country" class="w-full">
              <div class="flex w-full space-x-6 h-full overflow-hidden">
                <div
                  class="border mt-8 w-1/2 hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="name" class="bg-white text-gray-700 px-1">
                        Add TeleConsultant to group
                        <span :class="$colors.required"> *</span>
                      </label>
                    </p>
                  </div>
                  <div class="md:w-full flex flex-col items-center p-1">
                    <div class="w-full">
                      <div class="flex flex-col items-center relative">
                        <div class="w-full">
                          <div
                            class="mt-2 p-1 bg-white flex border border-gray-200 rounded"
                          >
                            <div class="flex flex-auto flex-wrap"></div>
                            <input
                              @input="
                                searchSellersOrtelecon($event, 'TeleConsultant')
                              "
                              v-model="searchTextTeleConsultant"
                              placeholder="Search "
                              class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                            />
                            <div
                              class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
                            >
                              <button
                                class="cursor-pointer w-6 h-6 text-green-500 outline-none focus:outline-none"
                              >
                                <i class="material-icons">search</i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="bg-white my-4 max-h-select top-100 z-40 w-full rounded overflow-y-auto h-full"
                        >
                          <div
                            v-for="(user, index) in teleConsultants"
                            :key="index"
                            :value="user._id"
                            class="flex flex-col w-full"
                          >
                            <div
                              class="cursor-pointer bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1 w-full rounded-t hover:bg-teal-100"
                            >
                              <div
                                class="flex w-full items-center p-2 pl-2 relative hover:border-teal-100"
                              >
                                <div class="w-6 flex flex-col items-center">
                                  <div
                                    class="flex relative w-5 h-5 justify-center items-center m-1 mr-2"
                                  >
                                    <i class="material-icons mx-2">person</i>
                                  </div>
                                </div>

                                <div class="w-full items-center flex">
                                  <div class="mx-2 mt-2">
                                    {{ user.fullName }}
                                    <div
                                      class="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-500"
                                    >
                                      {{ user.email }}
                                    </div>
                                  </div>
                                </div>

                                <div class="w-full items-center flex">
                                  <div
                                    class="text-sm text-center leading-5 text-green-500 bg-green-100 px-4 font-semibold rounded-full text-gray-900"
                                  >
                                    {{ user.type }}
                                  </div>
                                </div>

                                <div class="w-6 flex flex-col items-center">
                                  <button
                                    class="cursor-pointer w-6 h-6 text-black-500 outline-none focus:outline-none"
                                    @click="addTeleConsultant(index)"
                                  >
                                    <i class="material-icons">add</i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- ************************************Selected Tele Consultant******************************************-->

                <div
                  class="border mt-8 w-1/2 h-200 hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="name" class="bg-white text-gray-700 px-1">
                        Selected TeleConsultant
                        <span :class="$colors.required"></span>
                      </label>
                    </p>
                  </div>

                  <div class="md:w-full flex flex-col items-center h-64">
                    <div
                      v-if="formData.teleConsultants.length <= 0"
                      class="py-2"
                    >
                      <span
                        class="w-full flex justify-center text-center py-20 border-gray-200 text-base"
                      >
                        <p
                          class="font-semibold flex text-green-500 whitespace-no-wrap"
                        >
                          <span class="">No TeleConsultant selected </span>
                        </p>
                      </span>
                    </div>
                    <div class="w-full">
                      <div class="flex flex-col items-center relative">
                        <div
                          class="absolute mt-4 bg-white top-100 z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj"
                        >
                          <div
                            v-for="(user, index) in formData.teleConsultants"
                            :key="index"
                            :value="user._id"
                            class="flex flex-col w-full"
                          >
                            <div
                              class="cursor-pointer bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1 w-full rounded-t hover:bg-teal-100"
                            >
                              <div
                                class="flex w-full items-center p-2 pl-2 relative hover:border-teal-100"
                              >
                                <div class="w-6 flex flex-col items-center">
                                  <div
                                    class="flex relative w-5 h-5 0 justify-center items-center m-1 mr-2 w-4 h-4 mt-1 rounded-full"
                                  >
                                    <div class="w-6 flex flex-col items-center">
                                      <div
                                        class="flex relative w-5 h-5 justify-center items-center m-1 mr-2"
                                      >
                                        <i class="material-icons mx-2"
                                          >person</i
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="w-full items-center flex">
                                  <div class="mx-2 -mt-1">
                                    {{ user.fullName }}
                                    <div
                                      class="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-500"
                                    >
                                      {{ user.email }}
                                    </div>
                                  </div>
                                </div>
                                <div class="w-full items-center flex">
                                  <div
                                    class="text-sm text-center leading-5 text-green-500 bg-green-100 px-4 font-semibold rounded-full text-gray-900"
                                  >
                                    {{ user.type }}
                                  </div>
                                </div>

                                <div class="w-6 flex flex-col items-center">
                                  <button
                                    class="cursor-pointer w-6 h-6 text-black-500 outline-none focus:outline-none"
                                    @click="deleteTeleConsultant(index)"
                                  >
                                    <i class="material-icons">delete</i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ********************************************************************************-->
            <div class="mt-6 pt-3 flex justify-center">
              <button
                class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-500 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
                @click="save"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        country: null,
        description: "",
        teleConsultants: [],
        sellers: [],
        type: "",
      },
      sellers: [],
      teleConsultants: [],
      listUser: [],
      listSellers: [],
      listteleConsultant: [],
      roles: [],
      limit: 10,
      searchTextSeller: "",
      searchTextTeleConsultant: "",
      options: this.$countries,
      selectContry: "",
      wharhouse: null,
      warehouses: [],
    };
  },
  async mounted() {
    await this.getWarehouses();
    await this.getSellersOrTelecon("Seller");
    await this.getSellersOrTelecon("TeleConsultant");
    await this.dataToEdit();
  },
  methods: {
    RouterBack: function () {
      this.$router.back();
    },
    async getWarehouses() {
      const filter = { limit: 1000 };
      const res = await this.$server.search("warehouses", filter);
      if (res.content.results) {
        let data = res.content.results;
        for (let i in data) {
          this.warehouses.push({
            name: this.$f.getCountryNameByCode(this.options, data[i].country),
            code: data[i].country,
          });
        }
      } else this.warehouses = [];
    },
    async setSelectedContry(contry) {
      if (contry) {
        this.formData.country = contry.name;
        this.selectContry = contry.code;
        this.formData.sellers = [];
        this.formData.teleConsultants = [];
        this.wharhouse = await this.getwharhouse(contry.code);
        await this.getSellersOrTelecon("Seller");
        await this.getSellersOrTelecon("TeleConsultant");
      }
    },
    async getwharhouse(country) {
      const resW = await this.$server.get("warehouses", { country: country });
      if (resW.content) return resW.content;
    },
    async getSellersOrTelecon(type) {
      if (this.selectContry) {
        const res = await this.$server.search("users", {
          type: type,
          countries: this.selectContry,
        });

        if (res.content.results) {
          if (type == "Seller") this.sellers = res.content.results;
          else this.teleConsultants = res.content.results;
        } else {
          if (type == "Seller") this.sellers = [];
          else this.teleConsultants = [];
        }
      }
    },
    searchSellersOrtelecon: _.debounce(async function (search, type) {
      const filter = {
        type: type,
        limit: this.limit,
        countries: this.selectContry,
        name: search.target.value,
      };

      const res = await this.$server.find("users", filter);

      if (res.content.results) {
        if (type == "Seller") this.sellers = res.content.results;
        else this.teleConsultants = res.content.results;
      } else {
        this.sellers = [];
        this.teleConsultants = [];
      }
    }, 500),

    Verification(tab, tab1) {
      for (let i = 0; i < tab.length; i++) {
        for (let j = 0; j < tab1.length; j++) {
          if (tab[i]._id == tab1[j]._id) {
            tab1.splice(j, 1);
          }
        }
      }
    },

    /**************************************Sauvegarde function *******************************/
    async CheckIfsellerExist(data, method) {
      //check seller exist in another team
      let res = false;
      for (let i in data) {
        let filter = { sellers: data[i]._id, country: this.selectContry };
        if (method == "update") filter["Not_this_id"] = this.$route.params.id;

        const editData = await this.$server.search("teams", filter);
        if (editData.content.results && editData.content.results.length >= 1) {
          res = true;
          break;
        }
      }
      return res;
    },
    async UpdateManyUserTeam(dataSeller, team) {
      let idsSeller = [];
      for (let i in dataSeller) {
        idsSeller.push(dataSeller[i]._id);
      }

      let dateU = {
        ids: idsSeller,
        data: { country: this.selectContry, team: team },
      };
      return await this.$server.updateMany("users", dateU);
    },
    async save() {
      if (this.formData._id) {
        if (
          this.formData.sellers.length > 0 &&
          this.formData.teleConsultants.length > 0 &&
          this.formData.country
        ) {
          if (this.checkData()) {
            //  const checksellerexist=await this.CheckIfsellerExist(this.formData.sellers,'update');
            //  if(checksellerexist){
            //       alert("Seller in the list aready exist in another team", "warning");
            //       return false;
            //  }
            this.formData.country = this.selectContry;
            const data = await this.$server.update("teams", this.formData);
            if (data && data._id) {
              alert("team updated", "success");
              await this.UpdateManyUserTeam(
                this.formData.sellers,
                this.formData
              );
            } else alert(data, "warning");
          } else {
            alert(`you must fill all the fields !! `, "warning");
          }
        } else {
          alert(`you must select members !! `, "warning");
        }
      } else {
        if (
          this.formData.sellers.length > 0 &&
          this.formData.teleConsultants.length > 0 &&
          this.formData.country
        ) {
          if (this.checkData()) {
            this.formData.country = this.selectContry;
            // const checksellerexist=await this.CheckIfsellerExist(this.formData.sellers,'create');

            //  if(checksellerexist){
            //    alert("Seller in the list aready exist in another team", "warning");
            //    return false;
            //  }
            const data = await this.$server.create("teams", this.formData);
            if (data && data._id) {
              alert("team created", "success");
              await this.UpdateManyUserTeam(this.formData.sellers, data);
              this.formData = {
                name: "",
                warehouse: null,
                description: "",
                teleConsultants: [],
                sellers: [],
                type: "",
              };
            } else alert(data, "warning");
          } else {
            alert(`you must fill all the fields !! `, "warning");
          }
        } else {
          //
          alert(`you must select members !! `, "warning");
        }
      }
    },
    checkData() {
      if (
        this.formData.name &&
        this.formData.sellers &&
        this.formData.teleConsultants
      ) {
        return true;
      } else {
        return false;
      }
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("teams", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
        this.wharhouse = await this.getwharhouse(this.formData.country);
        this.selectContry = this.formData.country;
        this.formData.country = this.$f.getCountryNameByCode(
          this.options,
          this.selectContry
        );
        await this.getSellersOrTelecon("Seller");
        await this.getSellersOrTelecon("TeleConsultant");
        // this.Verification(this.formData.sellers, this.listSellers);
        // this.Verification(
        //   this.formData.teleConsultants,
        //   this.listteleConsultant
        // );
      }
    },

    /*************************************************************** */
    addSeller(index) {
      const seller = this.sellers[index];
      var index = this.formData.sellers.findIndex(function (o) {
        return o._id === seller._id;
      });
      if (index !== -1) {
        alert("This seller already exists in the group", "warning");
      } else {
        this.formData.sellers.push(seller);
      }
    },
    deleteSeller(index) {
      this.formData.sellers.splice(index, 1);
    },

    addTeleConsultant(index) {
      const seller = this.teleConsultants[index];
      var index = this.formData.teleConsultants.findIndex(function (o) {
        return o._id === seller._id;
      });
      if (index !== -1) {
        alert("This teleConsultant already exists in the group", "warning");
      } else {
        this.formData.teleConsultants.push(seller);
      }
    },
    deleteTeleConsultant(index) {
      this.formData.teleConsultants.splice(index, 1);
    },

    /********************************************************************************************/
  },
};
</script>

<style>
.top-100 {
  top: 100%;
}
.bottom-100 {
  bottom: 100%;
}
.max-h-select {
  max-height: 300px;
}
.border-users {
  border: 1px solid #efefef;
}
</style>
